import React from "react"
import { graphql } from "gatsby"
import { body } from "./blog-template.module.css"
import { date } from "./blog-template.module.css"
import { title } from "./blog-template.module.css"
import MainLayout from "../components/mainlayout"
import SEO from "../components/seo.js"

export default function BlogPost({ data }) {
    const post = data.markdownRemark

    return (
        <MainLayout>
          <SEO title={post.frontmatter.title} description={post.frontmatter.description} />
          <div className={title}>
            {post.frontmatter.title}
          </div>
          <div className={date}>
            Posted: {post.frontmatter.date}
          </div>
          <div className={body}>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </div>
        </MainLayout>
    )
}

export const query = graphql`
query ($slug: String!) {
  markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    frontmatter {
      date(fromNow: true)
      description
      title
    }
  }
}
`
